* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 900px;
  margin: auto;
}

header {
  text-align: center;
  background-color: rgb(227, 203, 172);
  padding: 10px;
  border-bottom: 5px solid rgb(53, 34, 9);
}

header .row {
  margin: auto;
  justify-content: space-around;
  
}

header .row .column {
  text-transform: capitalize;
  font-weight: 900;
}

main {
  min-height: 500px;
  background-color:rgb(238, 207, 198);
  text-align: center;
  padding: 20px;
  border-bottom: 5px solid rgb(170, 50, 17);
}
